import React from 'react'
import styled from 'styled-components'
import { media } from '../../utils/Media'





const LegalHeaderStyles = styled.header`
    width: 100%;
    background: ${props => props.theme.colors.grey};
    padding-top: 5rem;
        h1 {
            padding: 2rem 0;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            font-family: ${props => props.theme.font.family.boldTall}!important;
            font-size: 3rem!important;
            @media ${media.md} {
                font-size: 4rem!important;
            }
        }
`
const LegalHeader = ({ title }) => {
    return (
        <LegalHeaderStyles>
            <h1>{title}</h1>
        </LegalHeaderStyles>
    )
}

export default LegalHeader
