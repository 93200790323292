import React from 'react'
import styled from 'styled-components'

const LegalWrapperStyles = styled.section`
    width: 100%;
    section {
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        padding: 1.5rem 0;
        p, li, a  {
            font-size: .95rem!important;
        }
        strong {
            font-size: 1.1rem!important;
        }
    }
`
const LegalWrapper = ({ children }) => {
    return (
        <LegalWrapperStyles>
            <section>
                {children}
            </section>
        </LegalWrapperStyles>
    )
}

export default LegalWrapper
